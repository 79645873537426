import React from "react";
import styled from "styled-components";
import { BasicTemplate } from "@templates/BasicTemplate";
import { graphql } from "gatsby";
import { Button } from "@components/atoms/Button";
import { Link } from "gatsby-plugin-react-i18next";
import { Routes } from "@routes";
import { breakpoints } from "@theme/breakpoints";

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  padding: 0 20px;

  ${breakpoints.phoneMax`
    margin-top: 150px;
  `}

  h1 {
    font-size: 8rem;
    margin: 0;

    span {
      color: ${({ theme }) => theme.primary};
    }
  }

  h2 {
    margin-top: 10px;
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.washStrong};
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  text-decoration: none;
  text-align: center;
`;

export default () => {
  return (
    <BasicTemplate title="404 Page not found" description="">
      <StyledWrapper>
        <h1>
          4<span>0</span>4
        </h1>
        <h2>Page not found</h2>
        <StyledButton secondary forwardedAs={Link as any} to={Routes.Home}>
          Back to home
        </StyledButton>
      </StyledWrapper>
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
